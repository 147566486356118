
.main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 20px 20px 20px;

    .main-wrapper-table {
        display: flex;
        flex-direction: column;
        height: 100%;

        .main-wrapper-scrollbar {
            height: 100%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }

    .goods-text-table {
        .answer {
            display: flex;
            align-items: center;

            img {
                width: 80px;
                height: 80px;
                margin-right: 15px;
            }
        }

        .a-link {
            height: 80px;
            display: flex;
            align-items: center;

            .text {
                flex: 1;
                width: 1%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
        }
    }

    .dialog-footer {
        text-align: center;
        margin-top: 20px;
    }

    /*.order-main{*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    height: 100%;*/
    /*}*/
    .header{
        margin: 20px 0;
    }
    .isClick{
        background-color:#1CB064;
        color: #fff;
    }
}
.goods_info{
    display: flex;
    font-size: 14px;
   
    .good_images{
    width: 50px;
    height: 50px;
    // display: block;
}.goods-title{
    height: 50px;
    margin-left: 10px;
}
}
.evContent{
    font-size: 14px;
    .images{
        display: flex;
       
        div{
            // width: 40px;
            // height: 40px;
            .evimg{
            width: 40px;
            height: 40px;
            margin-right: 10px;
            img{
                width: 100%; /* 图片宽度占满容器 */
                height: 100%; /* 图片高度占满容器 */
                object-fit: cover; /* 图片不变形，占满整个盒子 */
            }
       
        }
        }
    }
}
::v-deep .el-table__row td {
vertical-align: top;//不垂直居中
margin-top: 10px;
}
.pagination{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.exam_content{
    // overflow-y: auto;
    // height: 716px;
}
.content{
    width:100%;
    // height:70px;
    overflow: hidden; /* 隐藏溢出的内容 */
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
// display: -webkit-box; /* 使用旧的 WebKit 语法 */
//   -webkit-box-orient: vertical; /* 垂直方向排列 */
//   overflow: hidden; /* 隐藏溢出的内容 */
//   -webkit-line-clamp: 1; /* 显示的行数 */
}
.big-img{
    width: 100%;
    height: 500px;
    // background-color: pink;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

